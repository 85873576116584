import { VFC } from 'react';
import TopPage from 'features/top/pages/TopPage';
import useUserLifelogRecords from 'features/top/hooks/use-user-lifelog-records';
import useTop from 'features/top/hooks/use-top';
import useNavigationBarItemsSetting from 'global/hooks/use-navigation-bar-items-setting';
import ScrollToTopWhenMounted from 'global/components/ScrollToTopWhenMounted/ScrollToTopWhenMounted';
import useEntryCampaigns from 'features/entry-campaign/hooks/use-entry-campaigns';

import useCheckFunctionalStatus from 'features/functional-status/hooks/use-check-functional-status';
import { TopDailyScoresRepositoryImpl } from '../data/repositories/top-daily-scores-repository';
import { TopScoresRepositoryImpl } from '../data/repositories/top-scores-repository';

const TopContainer: VFC = () => {
  useUserLifelogRecords();
  useNavigationBarItemsSetting({ type: 'logo' });

  void useCheckFunctionalStatus('refer_a_friend');

  const {
    scoresFetchResult,
    dailyScoresFetchResult,
    dailyScoresNextButtonTapped,
    dailyScoresPrevButtonTapped,
    offersFetchResult,
    announcementBannersFetchResult,
    advertisementBannersFetchResult,
    advertisementBannerTapped,
  } = useTop(new TopScoresRepositoryImpl(), new TopDailyScoresRepositoryImpl());

  const { fetchResult: entryCampaigns, entryCampaignBannerTapped } =
    useEntryCampaigns();

  return (
    <ScrollToTopWhenMounted>
      <TopPage
        scoresFetchResult={scoresFetchResult}
        dailyScoresFetchResult={dailyScoresFetchResult}
        dailyScoresNextButtonTapped={dailyScoresNextButtonTapped}
        dailyScoresPrevButtonTapped={dailyScoresPrevButtonTapped}
        offersFetchResult={offersFetchResult}
        entryCampaigns={entryCampaigns.getValue()}
        entryCampaignBannerTapped={entryCampaignBannerTapped}
        announcementBannersFetchResult={announcementBannersFetchResult}
        advertisementBannersFetchResult={advertisementBannersFetchResult}
        advertisementBannerTapped={advertisementBannerTapped}
      />
    </ScrollToTopWhenMounted>
  );
};

export default TopContainer;
