import { CSSProperties, FC, useEffect, VFC } from 'react';
import Router from 'app/routes';
import ApiClient from 'data/api-client/api-client';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import GlobalNavigation from 'global/components/global-navigation/GlobalNavigation';
import GlobalNavigationDisplayableContext from 'global/contexts/GlobalNavigationDisplayableContext';
import useGlobalNavigation from 'global/components/global-navigation/use-global-navigation';
import { GLOBAL_NAVIGATION_HEIGHT_PX } from 'global/constants';
import queryString from 'query-string';
import useGlobalMenuBatchItems from 'global/hooks/use-global-menu-batch-items';
import GlobalNavigationItem, {
  GlobalNavigationBadgeState,
} from 'global/components/global-navigation/global-navigation-item';
import useIntroductionProgress from 'features/introduction-progress/hooks/use-introduction-progress';
import useNavigationButtonHandler from 'global/hooks/use-navigation-button-handler';
import requestWebCommand, {
  SetUnreadNotificationsCount,
} from 'global/utilities/web-command';
import ClientEnvIdentifier from 'global/components/ClientEnvIdentifier/ClientEnvIdentifier';
import useClearCaches from 'global/hooks/use-clear-caches';
import { Helmet } from 'react-helmet';
import EmailRegistrationAuth from 'features/email-registration-auth/containers'

const GlobalSettings: VFC = () => {
  const history = useHistory();
  const { clearCaches } = useClearCaches();
  const { search } = useLocation();
  const qs = queryString.parse(search);
  const fincUserId = qs.finc_user_id as string | null;

  useEffect(() => {
    // 特定のページにアクセスした場合、sessionが切れているとログイン画面にリダイレクトされる。
    // ログイン時にはFiNCユーザーとFitStatsユーザーが1対1の関係を保つためserverでfinc_user_idのチェックが実施されており、リクエストparamに含める必要がある。
    // https://github.com/FiNCDeveloper/personal_data_trust_bank_client/blob/master/src/features/sign-in/email-password/hooks/use-sign-in.ts#L22
    // finc_user_idの検証ができないとログイン不可のため、どのページにアクセスされてもfinc_user_id（暗号化されたもの）を保存するようにする。
    if (fincUserId) {
      localStorage.setItem('fincUserId', fincUserId);
    }
  }, [fincUserId]);

  const authenticationExpired = () => {
    // react-query等のキャッシュをクリアする
    clearCaches();
    history.push('/');
  };

  const didReceiveServiceUnavailable = () => {
    history.push('/maintenance');
  };

  ApiClient.setDidAuthenticationExpired(authenticationExpired);
  ApiClient.setDidReceiveServiceUnavailable(didReceiveServiceUnavailable);

  return <></>;
};

const GlobalContainer: FC = ({ children }) => {
  const history = useHistory();
  const {
    fetchBatchItems,
    numberOfUnreadOffers,
    numberOfUnachievedPrograms,
    numberOfNewCompanies,
    numberOfNotifications,
  } = useGlobalMenuBatchItems();

  const badgeStates: GlobalNavigationBadgeState[] = [
    { type: 'offer', badgeNumber: numberOfUnreadOffers },
    { type: 'program', badgeNumber: numberOfUnachievedPrograms },
    { type: 'mydata', badgeNumber: numberOfNewCompanies },
  ];

  const {
    navigationItems,
    needsGlobalNavigation,
    selectedNavigationItemType,
    navigationItemClicked,
    isTapEnabled,
  } = useGlobalNavigation();

  const { redirectToRecoveryPoint } = useIntroductionProgress();

  useEffect(() => {
    // 認可・認証フローでどこかしらにリダイレクトされるので、ここでのリダイレクトは余計
    if (history.location.pathname === '/sign_up/landing') {
      fetchBatchItems();

      return;
    }

    // 初回マウント時にグロナビのバッチ表示を更新する
    redirectToRecoveryPoint(false);
    fetchBatchItems();
  }, [redirectToRecoveryPoint, fetchBatchItems, history]);

  useEffect(() => {
    requestWebCommand(new SetUnreadNotificationsCount(numberOfNotifications));
  }, [numberOfNotifications]);

  const onClickGlobalNavigation = (item: GlobalNavigationItem) => {
    fetchBatchItems();
    navigationItemClicked(item);
  };

  useNavigationButtonHandler();

  const globalContainerStyle: CSSProperties = needsGlobalNavigation
    ? { paddingBottom: `${GLOBAL_NAVIGATION_HEIGHT_PX}px` }
    : {};

  return (
    <>
      {process.env.REACT_APP_CLIENT_ENV !== 'production' && (
        <ClientEnvIdentifier clientEnv={process.env.REACT_APP_CLIENT_ENV} />
      )}
      <GlobalNavigationDisplayableContext.Provider
        value={needsGlobalNavigation}
      >
        <div style={globalContainerStyle}>
          {children}
          {needsGlobalNavigation && (
            <GlobalNavigation
              navigationItems={navigationItems}
              badgeStates={badgeStates}
              selectedItemType={selectedNavigationItemType}
              onClick={onClickGlobalNavigation}
              isTapEnabled={isTapEnabled}
            />
          )}
        </div>
      </GlobalNavigationDisplayableContext.Provider>
      <div id="tutorial-body" />
    </>
  );
};

const App: FC = () => (
  <>
    <div>
      {!!process.env.REACT_APP_GA_MEASUREMENT_ID && (
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_MEASUREMENT_ID}`}
          />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.REACT_APP_GA_MEASUREMENT_ID}');
            `}
          </script>
        </Helmet>
      )}
    </div>

    <BrowserRouter>
      <Switch >
        <Route exact path="/users/registration/email/auth" component={EmailRegistrationAuth} />
        <GlobalContainer>
          <GlobalSettings />
          <Router />
        </GlobalContainer>
      </Switch>
    </BrowserRouter>
  </>
);
export default App;
