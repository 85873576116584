import ApiClient from 'data/api-client/api-client';
import RequestConfig, { URL } from 'data/request-configs/request-config';
import FitStatsTicketsDto from '../dto/fit-stats-tickets-dto';

const createFitStatsTicketsRequestConfig = (): RequestConfig => ({
  endpoint: {
    baseUrl: URL.FIT_STATS,
    path: `/users/me/fit_stats_tickets`,
  },
  method: 'post',
  parameters: {},
});

interface FitStatsTicketsRepository {
  issue: () => Promise<FitStatsTicketsDto>;
}

export class FitStatsTicketsRepositoryImpl implements FitStatsTicketsRepository {
  issue = async (): Promise<FitStatsTicketsDto> =>
    new ApiClient().connect(createFitStatsTicketsRequestConfig());
}

export default FitStatsTicketsRepository;